import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <h1>Deam is a Digital Design Agency</h1>
  </Layout>
)

export default IndexPage
